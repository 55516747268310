<template>
    <div class="user-details">
        <header class="page-header">
            <h2>User profile</h2>
        </header>
        <div class="content-wrapper">
           <user-details-form :record="record"></user-details-form>
        </div>
    </div>
</template>

<script>

import UserModel from '../../models/UserModel.js'

import UserDetailsForm from '../../components/users/UserDetailsForm.vue';
import axios from 'axios';

export default {
    components: {
        UserDetailsForm
    },
    data: function(){

        return {
            record: this.$root.$data[UserModel.name][0]
        }
    },
    created: function(){
        axios.get('https://mockapi.starleasing.dev/users').then((response) => {
            this.$root.$data[UserModel.name].populate(response.data);
        }, (e) => {
            console.log(e);
            //!! need handler for errors
        });
    }
}
</script>
<style lang="scss">
@import "../../assets/scss/variables.scss";
</style>